module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-d88e18be3f/0/cache/gatsby-plugin-styled-components-npm-6.13.1-a700bfd5f0-8b8b595f81.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"minify":true,"displayName":true,"fileName":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-8b4319d0e4/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-00c76451d0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/image/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92783f9c99ecfeeaa51c48df520459a7"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-0c6854b259/0/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-d6f6f86ea3.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":90,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-68785ab98d/0/cache/gatsby-remark-autolink-headers-npm-6.13.1-0091404466-356e146e00.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-b5b7fded38/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
